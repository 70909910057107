<template>
  <action-dispatcher
    action="opportunity/getMy"
    :parameters="parameters"
    @success="onSuccess"
    @finally="onFinally"
    @error="onError"
  >
    <div class="view-my-opportunities">
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="flex-fill">
            <h2 class="text-uppercase">Minhas oportunidades</h2>
            <p class="text-light mb-5">
              Encontre o profissional ideal para trabalhar em seus serviços.
            </p>
          </div>
          <div class="d-none d-lg-block">
            <router-link
              :to="{ name: 'new-opportunity' }"
              class="btn btn-primary"
            >
              Publicar oportunidade
            </router-link>
          </div>
        </div>
        <div class="d-lg-none mb-4">
          <router-link
            :to="{ name: 'new-opportunity' }"
            class="btn btn-primary btn-block"
          >
            Publicar oportunidade
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-3 mb-4 mb-lg-0">
            <filter-box
              :options="status"
              v-model="status_filters"
              @on-empty="onEmptyFilters"
            />
          </div>
          <div class="col-lg-9">
            <div class="row mb-4">
              <div class="col-lg-8 mb-3 mb-lg-0">
                <search-input
                  placeholder="Pesquisar"
                  v-model="paramsFilter.q"
                />
              </div>
              <div class="col-lg-4">
                <order-by v-model="paramsFilter.order_by" />
              </div>
            </div>
            <div v-if="selected_categories.length" class="mb-4">
              <h3 class="text-uppercase mb-3">
                Resultados para "{{ selected_categories.join(", ") }}"
              </h3>
              <div v-for="(cat, i) in selected_categories" :key="`cat-${i}`">
                <b-form-group
                  v-model="selected_tags"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    :id="`checkbox-group-${i}`"
                    class="font-weight-bold"
                    :options="
                      normalizedTags[normalizeCat(cat)].map(text => ({
                        text
                      }))
                    "
                    :aria-describedby="ariaDescribedby"
                    :name="`flavour-${i}`"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <hr />
            </div>
            <div v-if="my_opportunities.length">
              <div
                v-for="(opportunity, index) in my_opportunities"
                :key="`opportunity-${index}`"
                class="mb-4"
              >
                <opportunities-item
                  is-my
                  :opportunity="opportunity"
                  @on-new-proposal="showNewProposal"
                  @show-proposals="showProposals"
                  @finish-project="finishProject"
                  @complete-project="completeProject"
                  @resume-project="resumeProject"
                />
              </div>
              <paginator
                v-model="paramsFilter.page"
                :pagination="pagination"
                class="mb-5"
              />
            </div>
            <div v-else class="d-flex justify-content-center pt-4">
              <h4 class="text-secondary">
                Não há oportunidades cadastradas até o momento
              </h4>
            </div>
          </div>
        </div>
        <div class="d-lg-none py-5"></div>
      </div>

      <!-- Modals -->
      <new-proposal
        v-if="current_opportunity"
        v-model="new_proposal_show"
        :opportunity="current_opportunity"
      />

      <my-proposals
        v-if="current_opportunity"
        v-model="proposals_show"
        :proposals="current_proposals"
        :opportunity="current_opportunity"
        @finish-project="finishProject"
        @accept-proposal="acceptProposal"
      />

      <confirmation
        v-model="show_success_confirm_end_service"
        title="Concluido!"
        text="O serviço foi encerrado"
        icon-type="success"
        cancel-button-text=""
        confirm-button-text="Fechar"
        confirm-button-class="btn-primary"
        @confirm="show_success_confirm_end_service = false"
      />

      <confirmation
        v-model="show_success_confirm_completed_service"
        title="Concluido!"
        text="O serviço foi concluído"
        icon-type="success"
        cancel-button-text=""
        confirm-button-text="Fechar"
        confirm-button-class="btn-primary"
        @confirm="show_success_confirm_completed_service = false"
      />

      <!-- Modals End -->
    </div>
  </action-dispatcher>
</template>
<script>
import { paramsMixin } from "@/mixins";
import OpportunitiesItem from "@/components/opportunity/Item.vue";
import NewProposal from "@/components/proposal/New.vue";
import MyProposals from "@/components/proposal/My.vue";
import { normalizeText, clearObject } from "@/functions/helpers";
import OrderBy from "@/components/filters/OrderBy.vue";
import FilterBox from "@/components/FilterBox.vue";
import { mapState } from "vuex";
import constants from "@/constants.js";

export default {
  mixins: [paramsMixin],
  components: {
    OpportunitiesItem,
    FilterBox,
    NewProposal,
    MyProposals,
    OrderBy
  },
  data() {
    const status_filters = this.$route.query?.status
      ? this.$route.query.status
          .split(",")
          .map(s => constants.JSON.STATUS_SLUG_ID_MAP[s])
      : [];
    return {
      showLoading: false,
      selected_categories: [],
      selected_tags: [],
      status_filters,
      current_opportunity: null,
      current_proposals: null,
      new_proposal_show: false,
      proposals_show: false,
      status: constants.JSON.STATUS,
      show_success_confirm_end_service: false,
      show_success_confirm_completed_service: false,
      paramsFilter: {
        category_id__in: "",
        status: "",
        name__icontains: "",
        title__contains: "",
        order_by: "",
        q: "",
        page: 1,
        ...this.$route.query
      }
    };
  },
  watch: {
    paramsFilter: {
      deep: true,
      handler(newVal) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            ...clearObject(newVal)
          }
        });
      }
    },
    status_filters: {
      deep: true,
      handler(newVal) {
        let list = this.mapName(newVal);
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            status: list
          }
        });
      }
    }
  },

  computed: {
    ...mapState("opportunity", ["my_opportunities", "pagination"]),
    ...mapState("professional", ["categories", "tags"]),
    parameters() {
      return { id: this.$user.user.id, params: this.$route.query };
    }
  },
  methods: {
    concat(value) {
      let arr = "";
      for (let i = 0; i < value.length; i++) {
        if (i == 0) {
          arr = value[i];
        } else {
          arr = arr + "," + value[i];
        }
      }
      return arr;
    },
    mapName(value) {
      let list = [];
      for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < 4; j++) {
          if (value[i] == this.status[j]?.id) {
            list.push(this.status[j]?.slug);
          }
        }
      }
      let finalList = this.concat(list);
      return finalList;
    },
    resumeProject(project) {},
    completeProject(project) {
      this.showLoading = true;
      this.$store
        .dispatch("opportunity/update", {
          id: project.id,
          status: "complete"
        })
        .then(() => {
          this.dispatch();
          this.show_success_confirm_completed_service = true;
        })
        .catch(err => {
          const error = err?.response?.data;
          const msg =
            error?.error ||
            error?.detail ||
            "Não foi possível complete o projeto.";
          this.$message.error(msg);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    finishProject(project) {
      this.showLoading = true;
      this.$store
        .dispatch("opportunity/update", {
          id: project.id,
          status: "closed"
        })
        .then(() => {
          this.dispatch();
          this.show_success_confirm_end_service = true;
        })
        .catch(err => {
          const error = err?.response?.data;
          const msg =
            error?.error ||
            error?.detail ||
            "Não foi possível finalizar o projeto.";
          this.$message.error(msg);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    acceptProposal(proposal) {
      this.showLoading = true;
      this.$store
        .dispatch("opportunity/updateProposal", {
          id: proposal.id,
          Accepted: "true"
        })
        .then(() => {
          this.dispatch();
          this.$message.success(
            `Você aceitou a proposta de'${proposal.perfil.name}'`
          );
        })
        .catch(err => {
          const error = err?.response?.data;
          const msg =
            error?.error ||
            error?.detail ||
            "Não foi possível atualizar a oportunidades";
          this.$message.error(msg);
        })
        .finally(() => {
          this.showLoading = false;
          this.proposals_show = false;
        });
    },
    onFinally() {
      this.showLoading = false;
    },
    onSuccess(data) {
      if (!data.length) {
        this.$message.warning(
          "Não há oportunidades que se encaixam neste filtro"
        );
      }
    },
    onError(err) {
      const error = err?.response?.data;
      const msg =
        error?.error ||
        error?.detail ||
        "Não foi possível carregar as oportunidades";
      this.$message.error(msg);
    },
    dispatch(params) {
      this.showLoading = true;
      this.$store
        .dispatch("opportunity/getMy", params || this.parameters)
        .then(this.onSuccess)
        .catch(this.onError)
        .finally(this.onFinally);
    },
    normalizeCat(v) {
      return normalizeText(v);
    },
    showNewProposal(p) {
      this.current_opportunity = p;
      this.new_proposal_show = true;
    },
    showProposals(p) {
      if (p?.proposals?.count == 0) {
        this.$message.warning(
          "Ainda não há propostas enviadas para esta oportunidade."
        );
        return;
      }
      this.current_opportunity = p.opportunity;
      this.current_proposals = p.proposals;
      this.proposals_show = true;
    },
    onEmptyFilters() {
      this.$router.push({
        ...this.$route,
        query: null
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-my-opportunities {
}
</style>
