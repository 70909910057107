<template>
  <div>
    <responsive-modal v-model="value">
      <div class="my-proposal-comp p-2 px-lg-3">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h3 class="text-dark font-weight-bold text-uppercase">
            Propostas recebidas
          </h3>
          <button class="btn close" @click="onClose">
            <i class="icon-close" />
          </button>
        </div>
        <div v-if="focused_proposal && !show_confirm_accept">
          <div class="d-flex align-items-center mb-4">
            <img
              width="72"
              height="72"
              class="profile-img mr-2"
              :src="focused_proposal.perfil_thumbnail || $user.placeholderUrl"
              :alt="focused_proposal.perfil.name"
            />
            <div class="flex-fill">
              <div class="d-flex align-items-center mb-2">
                <h6
                  class="text-left text-dark font-weight-bold text-uppercase m-0 p-0 mr-2"
                >
                  {{ focused_proposal.perfil.name }}
                </h6>
                <div class="d-flex align-items-center text-dark-yellow">
                  <i class="icon-star-filled mr-1"></i>
                  <small class="font-weight-bold">
                    {{
                      proposal_star_count(focused_proposal.perfil.rating_stars)
                    }}
                  </small>
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="icon-jobs mr-1"></i>
                <small class="text-dark">
                  {{ focused_proposal.perfil.category.name }}
                </small>
              </div>
              <div class="d-flex align-items-center">
                <div v-if="focused_proposal.perfil.site" class="px-0 mr-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <a
                      class="contact text-decoration-none website d-flex flex-column align-items-center align-items-lg-start"
                      :href="prepareSiteUrl(focused_proposal.perfil.site)"
                      target="_blank"
                    >
                      <div class="d-flex flex-column align-items-center">
                        <i class="icon-website"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <div v-if="focused_proposal.perfil.email" class="mr-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <a
                      target="_blank"
                      class="contact text-decoration-none phone d-flex flex-column align-items-center"
                      @click="copyToClipboard(focused_proposal.perfil.email)"
                    >
                      <i class="icon-email"></i>
                    </a>
                  </div>
                </div>
                <div v-if="focused_proposal.perfil.phone" class="mr-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <a
                      :href="
                        `https://api.whatsapp.com/send?phone=${formatPhone(
                          focused_proposal.perfil.phone
                        )}&text=Olá, tudo bem? Acabei de aceitar sua proposta lá no Bora Conecta e estou entrando em contato.`
                      "
                      class="contact text-decoration-none instagram d-flex flex-column align-items-center"
                      target="_blank"
                    >
                      <i class="icon-whatsapp"></i>
                    </a>
                  </div>
                </div>
                <div v-if="focused_proposal.perfil.social_media">
                  <div class="d-flex justify-content-center align-items-center">
                    <a
                      :href="instagramUrl(focused_proposal.perfil.social_media)"
                      target="_blank"
                      class="contact text-decoration-none instagram d-flex flex-column align-items-center align-items-lg-end"
                    >
                      <div class="d-flex flex-column align-items-center">
                        <i class="icon-instagram"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mb-4" />
          <p class="text-dark mb-4">
            {{ focused_proposal.description }}
          </p>
          <p class="text-secondary">
            <strong>Valor pretendido: </strong>
            <span class="text-dark font-weight-bold">
              R$ {{ focused_proposal.price | formatMoneyValue }}
            </span>
          </p>
          <p class="text-secondary">
            <strong>Prazo de entrega: </strong>
            <span class="text-dark font-weight-bold">
              {{ focused_proposal.deadline }} dias
            </span>
          </p>
          <div class="row">
            <div
              class="col-lg-6 px-4"
              :class="{ 'mx-auto': has_accepted_proposal }"
            >
              <div class="d-flex justify-content-center justify-content-lg-end">
                <button
                  class="btn btn-outline-secondary btn-block btn-h-sm"
                  @click="focused_proposal = null"
                >
                  Voltar
                </button>
              </div>
            </div>
            <div v-if="!has_accepted_proposal" class="col-lg-6 px-4">
              <div
                class="d-flex justify-content-center justify-content-lg-start"
              >
                <button
                  class="btn btn-primary btn-block btn-h-sm"
                  @click="submit"
                >
                  Aceitar proposta
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="focused_proposal && show_confirm_accept">
          <confirmation
            v-model="show_confirm_accept"
            :title="
              `Tem certeza que deseja aceitar a proposta de '${focused_proposal.perfil.name}'?`
            "
            text=""
            icon-type="warning"
            confirm-button-text="Aceitar proposta"
            cancel-button-text="Cancelar"
            cancel-button-class="btn-primary"
            confirm-button-class="btn-outline-dark"
            @cancel="show_confirm_accept = false"
            @confirm="acceptProposal"
          />
        </div>
        <div v-else-if="current_proposals.length">
          <button class="btn btn-block no-padding">
            <div
              v-for="(proposal, i) in current_proposals"
              :key="`prop-${i}`"
              class="d-flex align-items-center mb-4"
              :class="{
                'low-opacity': has_accepted_proposal && !proposal.Accepted
              }"
              @click="openProposal(proposal)"
            >
              <img
                class="d-lg-none mr-2 profile-img"
                height="52"
                width="52"
                :src="proposal.perfil_thumbnail || $user.placeholderUrl"
                :alt="proposal.perfil.name"
              />
              <img
                class="d-none d-lg-block mr-2 profile-img"
                height="72"
                width="72"
                :src="proposal.perfil_thumbnail || $user.placeholderUrl"
                :alt="proposal.perfil.name"
              />
              <div class="flex-fill">
                <div class="d-flex align-items-center mb-2">
                  <h6
                    class="text-left text-dark font-weight-bold text-uppercase m-0 p-0 mr-1 w-65"
                  >
                    {{ proposal.perfil.name }}
                  </h6>
                  <div class="d-flex align-items-center text-dark-yellow">
                    <i class="icon-star-filled mr-1"></i>
                    <small class="font-weight-bold">
                      {{ proposal_star_count(proposal.perfil.rating_stars) }}
                    </small>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <i class="icon-jobs mr-1"></i>
                  <small
                    v-if="proposal.Accepted"
                    class="accepted-label d-lg-none"
                  >
                    Proposta aceita
                  </small>
                  <small v-else class="text-left text-dark d-lg-none">
                    {{ proposal.perfil.category.name }}
                  </small>
                  <small class="text-left text-dark d-none d-lg-block">
                    {{ proposal.perfil.category.name }}
                  </small>
                </div>
              </div>
              <span
                v-if="proposal.Accepted"
                class="accepted-label d-none d-lg-block"
              >
                Proposta aceita
              </span>
              <i class="icon-arrow-right icon-color"></i>
            </div>
          </button>
        </div>

        <div v-else class="text-body py-5">
          Não foi feita nenhuma proposta ainda
        </div>
      </div>
    </responsive-modal>
    <confirmation
      iconType="check"
      title="Concluído!"
      v-model="success"
      confirmButtonClass="btn btn-primary"
      confirmButtonText="Fechar"
      text="A proposta foi aceita"
      @confirm="onClose"
    />
  </div>
</template>
<script>
import { formatMoney, removeMask } from "@/functions/helpers.js";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    opportunity: {
      type: Object,
      default: () => {}
    },
    proposals: {
      type: Object,
      default: () => {}
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value(v) {
      this.$emit("input", v);
      this.focused_proposal = null;
    }
  },
  filters: {
    formatMoneyValue(value) {
      return formatMoney(value);
    }
  },
  data() {
    return {
      focused_proposal: null,
      show_confirm_accept: false,
      success: false
    };
  },
  computed: {
    current_proposals() {
      return this.proposals?.results;
    },
    has_accepted_proposal() {
      return !!this.current_proposals.find(proposal => proposal.Accepted);
    }
  },
  methods: {
    proposal_star_count(star_count) {
      if (!star_count) return 0;

      return `${star_count}`.length > 1 ? `${star_count}` : `${star_count}.0`;
    },
    instagramUrl(insta) {
      if (!insta || !insta.length) return "";

      if (insta.indexOf("instagram.com") != -1) {
        return this.prepareSiteUrl(insta);
      }
      return `https://instagram.com/${insta.replace("@", "")}`;
    },
    formatPhone(phone) {
      const formatted = removeMask(phone);
      return formatted.slice(0, 2) == "55" ? formatted : `55${formatted}`;
    },
    prepareSiteUrl(site) {
      if (!site || !site.length) return "Sem site";

      if (site?.indexOf("http:") != -1) {
        const fixed = site.replace("http", "https");
        return fixed;
      }

      if (site?.indexOf("https://") == -1) return `https://${site}`;

      return site;
    },
    openProposal(proposal) {
      if (!this.has_accepted_proposal || proposal.Accepted) {
        this.focused_proposal = proposal;
      }
    },
    cancel() {},
    submit() {
      this.show_confirm_accept = true;
    },
    onClose() {
      this.visible = false;
      this.$emit("input", false);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$message.success("E-mail copiado!");
    },
    resetErrors() {
      this.errors = {
        email: [],
        password: [],
        non_field_errors: []
      };
    },
    acceptProposal() {
      this.$emit("accept-proposal", this.focused_proposal);
    }
  }
};
</script>

<style lang="scss" scoped>
.my-proposal-comp {
  .accepted-label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #41bf6d;
  }

  .icon-color {
    color: #cfcfcf !important;
  }

  .w-65 {
    width: 65% !important;
  }
  .btn {
    &.no-padding {
      padding: 0;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #000;
  }

  ::v-deep hr {
    border-top: 0.5px solid #8c8c8c40 !important;
  }

  button.close {
    i {
      font-size: 24px;
      color: #202124;
    }
  }

  .btn.close {
    color: #202124;
  }

  .icon-jobs {
    font-size: 28px;
    color: #000;
  }

  .icon-star-filled {
    font-size: 24px;
  }

  .icon-arrow-right {
    color: #000;
    font-size: 28px;
  }

  .low-opacity {
    opacity: 0.5;
  }

  .contact {
    word-break: break-all;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: black;
      margin-top: 0.3rem;
    }
    i {
      font-size: 22px;
      color: #737373;
    }
  }
}
</style>
